import React, { useState, useContext } from 'react';
import { Col, Card, Image, Pagination, Modal } from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SearchContext } from '../contexts/searchContext';
import { timeAgo, toTitleCase } from '../common/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import JobDetails from './jobDetailsComponent';

const JobList = ({ jobs, selectedJob, handleImageError }) => {
    const {
        setFilteredJobs,
        setLoading,
        setSelectedJob,
        totalPages,
        setPage,
        handleSearchCall,
        page
    } = useContext(SearchContext);

    const [showModal, setShowModal] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)'); // Use media query to detect mobile screens

    const handlePageChange = (newPage) => {
        setPage(newPage);
        handleSearchCall(newPage); // Fetch jobs for the new page
    };

    const handleCardClick = (job) => {
        setSelectedJob(job);

        // Show modal on mobile devices
        if (isMobile) {
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const getSalaryLabel = (maxSalary, currency, interval) => {
        if (!maxSalary || maxSalary <= 0.0) return null;

        const formattedSalary = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
        }).format(maxSalary);

        let shortCode = '';
        switch (interval) {
            case 'yearly':
                shortCode = '/yr';
                break;
            case 'monthly':
                shortCode = '/mo';
                break;
            case 'hourly':
                shortCode = '/hr';
                break;
            default:
                return null;
        }
        return `${formattedSalary}${shortCode}`;
    };

    // Limit visible page numbers and add ellipsis for long paginations
    const renderPagination = () => {
        const items = [];
        const maxVisiblePages = 5; // Adjust this number based on screen size

        let startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (startPage > 1) {
            items.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>1</Pagination.Item>);
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(<Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>);
        }

        return <Pagination>{items}</Pagination>;
    };

    return (
        <Col md={6}>
            {jobs.length ? (
                jobs.map((job) => (
                    <Card
                        key={job.id}
                        className={`mb-3 ${selectedJob?.id === job.id ? 'selected-card' : ''}`}
                        onClick={() => handleCardClick(job)}
                        style={{ cursor: 'pointer', position: 'relative' }}
                    >
                        <Card.Body className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <Image
                                    src={job.companyLogoUrl || '/images/default-company-logo.png'}
                                    rounded
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                    alt={`${job.company} logo`}
                                    onError={handleImageError}
                                    className="card-img"
                                />
                                <div>
                                    <Card.Title>{job.title}</Card.Title>
                                    <Card.Subtitle className="text-muted mb-1">{job.company}</Card.Subtitle>
                                    <Card.Text className="company-info">
                                        {`Employees: ${job.companyNumEmployees || 'N/A'}, Revenue: ${job.companyRevenue || 'N/A'}`}
                                    </Card.Text>
                                </div>
                            </div>
                            <div className="d-flex align-items-center text-muted">
                                <LocationOnIcon className="me-1" />
                                <span>{job.location}</span>
                                {job.maxSalary && (
                                    <>
                                        <span className="mx-2">•</span>
                                        <span>{getSalaryLabel(job.maxSalary, job.currency, job.salaryInterval)}</span>
                                    </>
                                )}
                            </div>
                            <div className="posting-date mt-auto">
                                {timeAgo(job.datePosted)}
                            </div>
                        </Card.Body>
                    </Card>
                ))
            ) : (
                <p>No jobs found.</p>
            )}

            {totalPages > 1 && (
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    {renderPagination()}
                </div>
            )}

            {/* Modal for mobile screens */}
            <Modal className='job-details-modal' show={showModal} onHide={closeModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Job Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='job-details-body'>
                    <JobDetails /> {/* Render job details inside the modal */}
                </Modal.Body>
            </Modal>
        </Col>
    );
};

export default JobList;
