import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Image, Pagination, Spinner } from 'react-bootstrap';
import { Link, useParams, Outlet } from 'react-router-dom'; // Import Outlet
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { timeAgo, toTitleCase } from '../common/utils';

// Slugify function to format the job title for the URL
const slugify = (title) => title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

const JobsByCategoryPage = () => {
    const { category } = useParams(); // Get the 'category' parameter from the URL
    const [latestJobs, setLatestJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        // Fetch latest jobs for the current page and category
        fetchLatestJobs(page, category);
    }, [page, category]); // Re-run when the page or category changes

    const fetchLatestJobs = async (page, category) => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs/by-category?category=${category}&page=${page}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setLatestJobs(data.data);
            setTotalPages(data.pagination.totalPages);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = '/images/default-company-logo.png'; // Use a default image if logo fails to load
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const renderPagination = () => {
        const items = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (startPage > 1) {
            items.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>1</Pagination.Item>);
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(<Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>);
        }

        return <Pagination>{items}</Pagination>;
    };

    return (
        <Container className="mt-4">
            <h1 className="text-center">Latest Jobs - {toTitleCase(category)}</h1>

            {/* Show Spinner when loading */}
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <Spinner animation="border" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <Row>
                        {latestJobs.map((job, index) => (
                            <Col xs={12} sm={6} md={6} key={index}>
                                {/* Wrap the entire Card in a Link */}
                                <Link
                                    to={`/job/${job.id}/${slugify(job.title)}`} // Use slugify for the job title
                                    style={{ textDecoration: 'none', color: 'inherit' }} // Remove underline and retain text color
                                >
                                    <Card className="mb-4" style={{ cursor: 'pointer', position: 'relative' }}>
                                        <Card.Body className="d-flex align-items-center">
                                            {/* Company logo */}
                                            <Image
                                                src={job.companyLogoUrl || '/images/default-company-logo.png'}
                                                rounded
                                                style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                                onError={handleImageError}
                                            />
                                            {/* Job Title and Company */}
                                            <div>
                                                <Card.Title>{toTitleCase(job.title)}</Card.Title>
                                                <Card.Subtitle className="text-muted">{job.company}</Card.Subtitle>
                                                <span>{job.location}</span>
                                                {/* Job posting age */}
                                                <div className="posting-date posting-date-bottom mt-auto">
                                                    {timeAgo(job.datePosted)}
                                                </div>
                                            </div>
                                            {/* Open in New Tab icon */}
                                            <OpenInNewIcon
                                                fontSize="small"
                                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>

                    {/* Pagination */}
                    {totalPages > 1 && (
                        <Row className="mt-4">
                            <Col className="d-flex justify-content-center">
                                {renderPagination()}
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {/* Render Outlet for nested routes */}
            <Outlet />
        </Container>
    );
};

export default JobsByCategoryPage;
