import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchText, setSearchText] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(4);
    const [totalPages, setTotalPages] = useState(1);
    const [handleSearchCall, setHandleSearchCall] = useState(1);

    return (
        <SearchContext.Provider value={{
            searchText,
            setSearchText,
            selectedLocation,
            setSelectedLocation,
            selectedJobType,
            setSelectedJobType,
            filteredJobs,
            setFilteredJobs,
            loading,
            setLoading,
            setSelectedJob ,
            selectedJob,
            totalPages,
            setTotalPages,
            page,
            setPage,
            pageSize,
            handleSearchCall,
            setHandleSearchCall
        }}>
            {children}
        </SearchContext.Provider>
    );
};
