import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import JobList from './jobListComponent';
import { SearchContext } from '../contexts/searchContext';
import JobDetails from './jobDetailsComponent';

const SearchResults = () => {
    const { filteredJobs, setFilteredJobs, selectedJob } = useContext(SearchContext);

    return (
        <Container className="mt-4">
            <Row>
                <JobList jobs={filteredJobs} setFilteredJobs={setFilteredJobs} />
                <JobDetails selectedJob={selectedJob} />
            </Row>
        </Container>
    );
};

export default SearchResults;
