import React from 'react';

const TermsOfUseMobile = () => {
  return (
    <div style={styles.container}>
      <h1>Terms and Conditions</h1>
      <p><strong>Effective From: 2024/01/01</strong></p>

      <p>Welcome to <strong>GoRemoteNow</strong>. By accessing or using our application, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our app.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using GoRemoteNow, you confirm that you are at least 18 years old and are capable of entering into a binding agreement. If you are using the app on behalf of an organization, you represent that you have the authority to bind that organization to these terms.</p>

      <h2>2. Use of the App</h2>
      <p>You agree to use GoRemoteNow only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the app.</p>

      <h2>3. Account Registration</h2>
      <p>To access certain features, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

      <h2>4. Job Listings</h2>
      <p>The app provides job listings from various companies. We do not guarantee the accuracy, completeness, or reliability of any job listing or the qualifications of any employer. You acknowledge that any reliance on such listings is at your own risk.</p>

      <h2>5. Intellectual Property</h2>
      <p>All content within GoRemoteNow, including but not limited to text, graphics, logos, and software, is the property of GoRemoteNow.co or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written permission.</p>

      <h2>6. Disclaimer of Warranties</h2>
      <p>GoRemoteNow is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not warrant that the app will be uninterrupted, secure, or error-free.</p>

      <h2>7. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, GoRemoteNow.co shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of GoRemoteNow or any content therein.</p>

      <h2>8. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to the app at our sole discretion, without prior notice or liability, for any reason, including if you breach these Terms and Conditions.</p>

      <h2>9. Changes to These Terms</h2>
      <p>We may modify these Terms and Conditions from time to time. Any changes will be effective immediately upon posting in the app. Your continued use of the app after any changes constitutes acceptance of the new Terms and Conditions.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about these Terms and Conditions, please contact us at: <strong>goremotenow.co@gmail.com</strong>.</p>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.6,
    margin: '20px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
};

export default TermsOfUseMobile;
