 // Helper function to calculate time ago
 export const timeAgo = (dateString) => {
    const now = new Date();
    const postedDate = new Date(dateString);
    const diffInMs = now - postedDate;
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    } else if (diffInDays < 30) {
        return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    } else {
        return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
    }
};

// src/utils/stringUtils.js

/**
 * Converts a string to title case.
 * Capitalizes the first letter of each word in the string.
 *
 * @param {string} str - The string to convert.
 * @returns {string} - The string with every word capitalized.
 */
export const toTitleCase = (str) => {
    if (!str) return '';
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
