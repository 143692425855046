import React from 'react';
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header';
import SearchBar from './components/searchBarComponent';
import './App.css';
import { SearchProvider } from './contexts/searchContext';
import Footer from './components/footer';

function App({ showHeaderAndFooter = true }) {  
  return (
    <SearchProvider>
      <div className="app-container">
        {showHeaderAndFooter && <Header />}
        {showHeaderAndFooter && <SearchBar />}
        <main className="main-content">
          <Outlet /> 
        </main>
        {showHeaderAndFooter && <Footer />} 
      </div>
    </SearchProvider>
  );
}

export default App;
