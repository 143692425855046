import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container" style={{ padding: '20px' }}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective From: 2024/01/01 </strong></p>

      <p>At <strong>GoRemoteNow.co</strong>, we are committed to protecting and respecting your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and how we safeguard your personal data.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Data:</strong> Information you provide to us voluntarily, such as your name, email address, and any other details provided through forms on our website.</li>
        <li><strong>Usage Data:</strong> Information about how you interact with our website, such as your IP address, browser type, and pages visited.</li>
        <li><strong>Cookies:</strong> We use cookies and similar technologies to enhance your experience on our website.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and maintain our services</li>
        <li>Personalize your experience</li>
        <li>Improve our website based on user feedback</li>
        <li>Communicate with you regarding updates or promotional offers</li>
      </ul>

      <h2>3. Sharing of Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except when necessary to provide the service (e.g., with trusted third-party service providers) or as required by law.</p>

      <h2>4. Security of Your Information</h2>
      <p>We implement appropriate security measures to protect your personal data. However, please be aware that no method of transmission over the Internet is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal data we hold about you</li>
        <li>Request correction of any incorrect or incomplete data</li>
        <li>Request deletion of your data, subject to certain exceptions</li>
      </ul>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:goremotenow.co@gmail.com">goremotenow.co@gmail.com
      </a></p>
    </div>
  );
};

export default PrivacyPolicy;
