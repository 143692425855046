import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container terms-of-use" style={{ padding: '20px' }}>
      <h1>Terms of Use</h1>
      <p><strong>Last Updated: 2024/01/01</strong></p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using <strong>goremotenow.co</strong>, you agree to these Terms of Use and all applicable laws and regulations. If you do not agree, please refrain from using the site.
      </p>

      <h2>2. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting. Continued use of the website constitutes acceptance of the revised terms.
      </p>

      <h2>3. Use of the Website</h2>
      <p><strong>Eligibility:</strong> You must be at least 18 years old to use this site.</p>
      <p><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the site for personal, non-commercial purposes.</p>

      <h3>Prohibited Activities</h3>
      <ul>
        <li>Use the website for any illegal activities.</li>
        <li>Access or attempt to access non-public areas of the website.</li>
        <li>Interfere with or disrupt the operation of the website.</li>
        <li>Attempt to scrape, data mine, or otherwise extract information from the site without authorization.</li>
      </ul>

      <h2>4. User Accounts</h2>
      <p>
        To access certain features of <strong>goremotenow.co</strong>, you may need to create an account. You agree to provide accurate and complete information when creating your account and to keep this information up-to-date. You are responsible for maintaining the confidentiality of your account credentials.
      </p>

      <h2>5. Job Listings and Applications</h2>
      <p>
        <strong>goremotenow.co</strong> is a platform that connects job seekers with employers offering remote opportunities. We do not guarantee the accuracy or legitimacy of any job listings. It is your responsibility to perform due diligence when applying for jobs or engaging with employers.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content on this website, including but not limited to text, images, logos, and software, is the intellectual property of <strong>goremotenow.co</strong> or its licensors. Unauthorized use, reproduction, or distribution is strictly prohibited.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        <strong>goremotenow.co</strong> is provided on an "as-is" basis. We make no warranties regarding the accuracy, reliability, or availability of the site. In no event shall we be liable for any damages arising from the use or inability to use the site.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms of Use are governed by the laws of [Your Jurisdiction]. Any disputes arising under these Terms shall be resolved in the courts of [Your Jurisdiction].
      </p>

      <h2>9. Contact Information</h2>
      <p>
        If you have any questions about these Terms of Use, please contact us at <a href="mailto:goremotenow.co@gmail.com">goremotenow.co@gmail.com</a>.
      </p>
    </div>
  );
};

export default TermsOfUse;
