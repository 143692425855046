import React, { useState, useEffect } from 'react';
import LatestJobs from './latestJobsComponent'; // Import LatestJobs
import Companies from './topCompaniesComponent';   // Import Companies
import './home.css';

// Fetch job types
const jobTypes = [
    { label: "Full Time", value: 1 },
    { label: "Part Time", value: 2 },
    { label: "Internship", value: 3 },
    { label: "Contract", value: 4 }
];

const fetchSectionsData = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/metadata/sections');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch section data:', error);
        return null;
    }
};


const Home = () => {
    const [showLatestJobs, setShowLatestJobs] = useState(true); 
    const [showCompanies, setShowCompanies] = useState(true);
    const [latestJobs, setLatestJobs] = useState([]); // Latest jobs data
    const [topCompanies, setTopCompanies] = useState([]); // Top companies data

    useEffect(() => {
        const loadSections = async () => {
            const sectionsData = await fetchSectionsData();
            if (sectionsData) {
                setLatestJobs(sectionsData.latestJobs);
                setTopCompanies(sectionsData.topCompanies);
            }
        };

        loadSections(); // Fetch the data on page load
    }, []);

    const handleImageError = (e) => {
        e.target.onerror = null; // prevent infinite loop
        e.target.src = '/images/default-company-logo.png'; // Use a default image or text
    };
 
    /*Search bar*/

    return (
        <div>
            <div className="banner">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Text Section */}
                        <div className="col-md-6">
                            <div className="banner-text">
                                <h1>Take Control of Your Career, GoRemoteNow</h1>
                                <p>Explore verified, flexible jobs—work from home or anywhere you choose.</p>
                            </div>
                        </div>
                        
                        {/* Image Section */}
                        <div className="col-md-6 text-right">
                            <div className="banner-image">
                                <img src="/images/banner-img-2.png" alt="Job Search" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
             {/* Latest Jobs Section */}
             {showLatestJobs && <LatestJobs latestJobs={latestJobs} handleImageError={handleImageError} />}

            {/* Companies Section */}
            {showCompanies && <Companies topCompanies={topCompanies} handleImageError={handleImageError} />}     
        </div>
    );
};

export default Home;
