import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Card, Dropdown, Image } from 'react-bootstrap';
import { marked } from 'marked';
import { useParams } from 'react-router-dom';
import { SearchContext } from '../contexts/searchContext';
import { toTitleCase } from '../common/utils';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const JobDetails = () => {
    const { jobId } = useParams(); // Get jobId from URL params
    const { selectedJob } = useContext(SearchContext);
    const [job, setJob] = useState(selectedJob); 
    
    // Scroll to top when the component is mounted or jobId changes
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [jobId]);

    useEffect(() => {
        if (!job && jobId) {
            fetchJobById(jobId);
        } else {
            setJob(selectedJob);
        }
    }, [jobId, selectedJob]);

    const handleImageError = (e) => {
        e.target.onerror = null; // prevent infinite loop
        e.target.src = '/images/default-company-logo.png'; // Use a default image or text
    };

    const handleDropdownSelect = (eventKey) => {
        if (job) {
            if (eventKey === 'apply_with') {
                window.open(job.jobUrl, '_blank');
            } else if (eventKey === 'apply_direct') {
                window.open(job.directJobUrl, '_blank');
            }
        }
    };

    const fetchJobById = async (id) => {
        const response = await fetch(process.env.REACT_APP_API_URL+`/jobs/job/${id}`);
        const jobData = await response.json();
        setJob(jobData);
    };

    return (
        <Col md={jobId ? 12 : 6} className="job-details-full-page container">
            {job ? (
                <Card className="sticky-panel">
                    <Card.Header className="sticky-top bg-light d-flex align-items-center" style={{ padding: '1rem' }}>
                        <Image
                            src={job.companyLogoUrl || '/images/default-company-logo.png'}
                            rounded
                            style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                            alt={`${job.company} logo`}
                            onError={handleImageError}
                        />
                        <div>
                            <h5>{toTitleCase(job.title)}</h5>
                            <p className="text-muted mb-0">{job.company}</p>
                        </div>
                        <Dropdown className="ms-auto" onSelect={handleDropdownSelect}>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                Apply
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="apply_with">Apply with {job.site}</Dropdown.Item>
                                <Dropdown.Item eventKey="apply_direct">Apply direct</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text dangerouslySetInnerHTML={{ __html: marked(job.description || '') }} />
                    </Card.Body>
                </Card>
            ) : (
                <p>No job selected.</p>
            )}
        </Col>
    );
};

export default JobDetails;
