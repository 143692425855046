import React from 'react';
import { Link } from 'react-router-dom'; // Import Link to enable navigation
import { toTitleCase } from '../common/utils';

function Footer() {
  const categories = [
    'senior-developer',
    'developer',
    'part-time',
    'full-time',
    'internship',
    'contract'
  ];

  return (
    <footer className="footer">
      <div className="container">
        <p>© 2024 GoRemoteNow.Co. All Rights Reserved.</p>
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
          &nbsp; | &nbsp;
          <Link to="/terms-of-use">Terms of Use</Link>
        </p>
        <p>
          <strong>Job Categories:</strong>
          {categories.map((category) => (
            <span key={category}>
              &nbsp; | &nbsp;
              <Link to={`/jobs/category/${category}`}>{toTitleCase(category.replace('-', ' '))}</Link>
            </span>
          ))}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
