import React from 'react';

const PrivacyPolicyMobile = () => {
  return (
    <div style={styles.container}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective From: 2024/01/01</strong></p>

      <p>At <strong>GoRemoteNow</strong>, we are dedicated to protecting your privacy. This Privacy Policy explains the types of information we collect, how we use it, and how we protect your personal data.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Data:</strong> Information you voluntarily provide, such as your name, email address, and other details when you create an account or fill out forms.</li>
        <li><strong>Usage Data:</strong> Information about how you use the app, including your device information, IP address, app usage patterns, and interactions.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience within the app.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and maintain our services</li>
        <li>Personalize your experience and tailor job listings to your preferences</li>
        <li>Improve our app based on user feedback and analytics</li>
        <li>Communicate with you regarding updates, job alerts, or promotional offers</li>
      </ul>

      <h2>3. Sharing of Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:</p>
      <ul>
        <li>With trusted third-party service providers who assist us in operating the app or servicing you, as long as those parties agree to keep this information confidential</li>
        <li>As required by law or to protect our rights</li>
      </ul>

      <h2>4. Security of Your Information</h2>
      <p>We implement appropriate security measures to protect your personal data. However, please understand that no method of transmission over the Internet is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal data we hold about you</li>
        <li>Request correction of any inaccurate or incomplete data</li>
        <li>Request deletion of your data, subject to certain exceptions</li>
      </ul>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy in the app.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at: <strong>goremotenow.co@gmail.com</strong>.</p>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.6,
    margin: '20px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
};

export default PrivacyPolicyMobile;
