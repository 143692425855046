import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import JobDetails from './components/jobDetailsComponent';
import SearchResults from './components/searchResultsComponent';
import PrivacyPolicy from './components/privacyPolicyComponent';
import TermsOfUse from './components/termsOfUseComponent';
import JobsByCategoryPage from './components/jobsByCategoryComponent';
import PrivacyPolicyMobile from './components/privacyPolicyMobileComponent';
import TermsOfUseMobile from './components/termsOfUseMobileComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App showHeaderAndFooter={true} />}>
            <Route index element={<Home />} />
            <Route path="job/:jobId/:jobTitle?" element={<JobDetails />} /> 
            <Route path="search" element={<SearchResults />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="jobs/category/:category" element={<JobsByCategoryPage />} />
        </Route>
        <Route path="/no-header" element={<App showHeaderAndFooter={false} />}>
          <Route path="terms-of-use" element={<TermsOfUseMobile />} />
          <Route path="privacy-policy" element={<PrivacyPolicyMobile />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
