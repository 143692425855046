import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // For navigation to full page job details
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Material icon for "Open in New"
import { timeAgo, toTitleCase } from '../common/utils';

// Slugify function to format the job title for the URL
const slugify = (title) => title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

const LatestJobs = ({ latestJobs, handleImageError }) => {
    return (
        <Container className="mt-4">
            <h2>Latest Jobs</h2>
            <Row>
                {latestJobs.map((job, index) => (
                    <Col xs={12} sm={6} md={6} key={index}>
                        {/* Wrap the entire card in Link */}
                        <Link to={`/job/${job.id}/${slugify(job.title)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card className="mb-3" style={{ cursor: 'pointer', position: 'relative' }}>
                                <Card.Body className="d-flex align-items-center">
                                    {/* Company logo */}
                                    <Image
                                        src={job.companyLogoUrl || '/images/default-company-logo.png'}
                                        rounded
                                        style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                        onError={handleImageError}
                                    />
                                    {/* Job Title and Company */}
                                    <div>
                                        <Card.Title>{toTitleCase(job.title)}</Card.Title>
                                        <Card.Subtitle className="text-muted">{job.company}</Card.Subtitle>
                                        <span>{job.location}</span>
                                        {/* Job posting age */}
                                        <div className="posting-date posting-date-bottom mt-auto">
                                            {timeAgo(job.datePosted)}
                                        </div>
                                    </div>

                                    {/* Open in New Tab icon */}
                                    <OpenInNewIcon fontSize="small" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default LatestJobs;
